export default {

  // menus and theme translations
  Dashboards: 'Dashboards',
  Analytics: 'Analytik',
  eCommerce: 'eCommerce',
  Dashboard: 'Dashboard',
  Tenders: 'Ausschreibungen',
  'Create Tender': 'Erstellen',
  'Tender List': 'Liste',
  CRM: 'CRM',
  Companies: 'Firmen',
  'Company List': 'Liste',
  'Add Company': 'Erstellen',
  'Add User': 'Erstellen',
  Reports: 'Berichte',
  Subscriptions: 'Abonnements',
  'User Management': 'Benutzerverwaltung',
  References: 'Verweise',
  'App examples': 'App-Beispiele',
  'User interface': 'User interface',
  'Forms & tables': 'Formulare & Tabellen',
  'Charts & maps': 'Karten & Pläne',
  'Other examples': 'Andere Beispiele',
  Calendar: 'Kalender',
  Chat: 'Plaudern',
  Email: 'Email',
  Invoice: 'Rechnung',
  List: 'Liste',
  Preview: 'Vorschau',
  Edit: 'Bearbeiten',
  Add: 'Hinzufügen',
  User: 'Benutzer',
  'User List': 'Liste',
  'User View': 'Aussicht',
  'APPS AND PAGES': 'APPS UND SEITEN',
  Pages: 'Seiten',
  Authentication: 'Authentifizierung',
  'Login V1': 'Anmelden V1',
  'Login V2': 'Anmelden V2',
  'Register V1': 'Registrieren V1',
  'Register V2': 'Registrieren V2',
  'Forgot Password V1': 'Passwort vergessen V1',
  'Forgot Password V2': 'Passwort vergessen V2',
  'Reset Password V1': 'Passwort zurücksetzen V1',
  'Reset Password V2': 'Passwort zurücksetzen V2',
  Miscellaneous: 'Sonstige',
  'Under Maintenance': 'Wird gewartet',
  Error: 'Fehler',
  'Coming Soon': 'Kommt bald',
  'Not Authorized': 'Nicht berechtigt',
  'Knowledge Base': 'Wissensbasis',
  'Account Settings': 'Account Einstellungen',
  Pricing: 'Preisgestaltung',
  FAQ: 'FAQ',
  'USER INTERFACE': 'BENUTZEROBERFLÄCHE',
  Typography: 'Typografie',
  Icons: 'Symbole',
  Gamification: 'Gamifizierung',
  Cards: 'Karten',
  Basic: 'Basic',
  Statistics: 'Statistiken',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Diagramm',
  Components: 'Komponenten',
  Alert: 'Alarm',
  Avatar: 'Benutzerbild',
  Badge: 'Abzeichen',
  Button: 'Taste',
  Menu: 'Menü',
  'Expansion Panel': 'Erweiterungspanel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Registerkarten',
  Tooltip: 'Kurzinfo',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Zeitleiste',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMEN & TABELLEN',
  'Forms Elements': 'Formularelemente',
  Autocomplete: 'Autovervollständigung',
  Checkbox: 'Kontrollkästchen',
  Combobox: 'Kombinationsfeld',
  'File Input': 'Dateieingabe',
  Radio: 'Radio',
  'Range Slider': 'Bereichsschieberegler',
  Select: 'Wählen',
  Slider: 'Schieberegler',
  Switch: 'Schalten',
  Textarea: 'Textarea',
  Textfield: 'Textfeld',
  'Date Picker': 'Datumsauswahl',
  'Month Picker': 'Monatsauswahl',
  'Time Picker': 'Zeitauswahl',
  Rating: 'Bewertung',
  'Form Layout': 'Formularlayout',
  'Form validation': 'Formularvalidierung',
  Tables: 'Tabellen',
  'Simple Table': 'Einfache Tabelle',
  Datatable: 'Datentabelle',
  'CHARTS AND MAPS': 'KARTEN UND KARTEN',
  Charts: 'Diagramme',
  'Apex Chart': 'Apex-Diagramm',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Andere',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',

  // user types select
  'Super admin': 'Höchster Vorgesetzter',
  'Cleangreen admin': 'Cleangreen-Administrator',
  Admin: 'Admin',

  // company types select
  Client: 'Klient',
  Supplier: 'Lieferant',
  'Quality assurance': 'Qualitätskontrolle',
  'Technical support': 'Technischer Support',
  'Platform owner': 'Plattformbesitzer',

  // statuses select
  Pending: 'Ausstehend',
  Active: 'Aktiv',
  Disabled: 'Inaktiv',
  Trashed: 'Gelöscht',

  // languages select
  English: 'Englisch',
  German: 'Deutsch',
  French: 'Französisch',
  Italian: 'Italienisch',

  // addresses
  'Contact address': 'Kontakt Anschrift',
  'Delivery address': 'Lieferadresse',
  'Object address': 'Objektadresse',
  'Billing address': 'Rechnungsadresse',
  'Other address': 'Andere Adresse',

  // tender categories select
  'Not specified': 'Unbestimmt',
  Delivery: 'Zustellung',
  Service: 'Service',
  'Construction work': 'Bauarbeiten',
  Competition: 'Wettbewerb',

  // tender types select
  'International tender': 'Internationale Ausschreibung',
  'Local tender': 'Lokale Ausschreibung',
  'Internal tender': 'Interne Ausschreibung',

  // tender actions select
  Duplicate: 'Kopieren',
  Delete: 'Löschen',

  // input field options select
  Subtitle: 'Untertitel',
  Description: 'Beschreibung',
  'Short text': 'Kurzer Text',
  'Long text': 'Langer Text',
  Number: 'Nummer',
  'Radio button': 'Radio knopf',
  'File upload': 'Datei-Upload',
  Date: 'Datum',

  // tender statuses select
  Draft: 'Entwurf',
  Semiinactive: 'Semiinaktiv',
  Inactive: 'Inaktiv',
  Archived: 'Archiviert',

  // procedure type select
  'Open procedure': 'Offenes Verfahren',
  'Selective procedure': 'Selektives Verfahren',
  'Hands-free procedure': 'Freihändiges Verfahren ',
  'Invitation procedure': 'Einladungs Verfahren',

  yes: 'Ja',
  no: 'Nein',

  tenders: {
    tenders: 'Ausschreibungen',
    tenderList: 'Ausschreibungsliste',
    tenderCreate: 'Erstellen',
  },
  general: {
    typeToSearch: 'Tippe um zu suchen',
    statusFilter: 'Statusfilter',
    categoryFilter: 'Kategoriefilter',
    language: 'Sprache',
    or: 'oder',
    welcomeMessage: 'Willkommen bei Esora!',
    forgotPassword: 'Passwort vergessen',
    save: 'Speichern',
    close: 'Schließen',
    ok: 'OK',
    cancel: 'Abbrechen',
    anErrorOccurred: 'Es ist ein Fehler aufgetreten. Versuchen Sie es erneut.',
    status: 'Status',
    name: 'Name',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    restore: 'Wiederherstellen',
    details: 'Einzelheiten',
    user: 'Benutzer',
    company: 'Firma',
    type: 'Typ',
  },
  login: {
    signInMessage: 'Bitte melden Sie sich bei Ihrem Konto an',
    email: 'Email',
    password: 'Passwort',
    rememberMe: 'Remember me',
    login: 'Anmeldung',
    newOnPlatform: 'Neu auf unserer Plattform?',
    createAccount: 'Ein Konto erstellen',
    invalidCredentials: 'Ungültige Daten, bitte korrigieren Sie die Felder und versuchen Sie es erneut',
    emailVerified: 'Ihre E-Mail wurde erfolgreich verifiziert',
  },
  register: {
    fillUpMessage: 'Bitte füllen Sie das folgende Formular aus',
    userAgreement: 'Ich stimme zu',
    privacyAndTerms: 'Datenschutzerklärung & Bedingungen',
    alreadyHaveAccount: 'Sie haben bereits ein Konto?',
    signInInstead: 'Melden Sie sich stattdessen an',
    successfullyRegistered: 'Ihr Konto wurde erfolgreich erstellt. Unsere Administration überprüft Ihr Konto und aktiviert es, '
      + 'wenn alle Daten in Ordnung sind, oder fragt bei Bedarf nach zusätzlichen Informationen. Bitte bestätigen Sie in der Zwischenzeit Ihre E-Mail.',
    invalidRegistrationData: 'Die eingegebenen Daten sind ungültig. Wenn Sie diese E-Mail bereits verwendet haben, melden Sie sich bitte an',
    resend: 'Erneut senden',
    resendEmailMessage: 'Keine Bestätigungs-E-Mail erhalten?',
    companyType: 'Wählen Sie einen Profiltyp',
  },
  forgotPassword: {
    enterEmail: 'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen Anweisungen zum Zurücksetzen Ihres Passworts',
    sendLink: 'Link zum Zurücksetzen senden',
    backToLogin: 'Zurück zur Anmeldung',
    emailSent: 'Wir haben Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts gesendet',
    resetPassword: 'Passwort zurücksetzen',
    passwordSuccessfullyReset: 'Ihr Passwort wird geändert. Sie können sich jetzt mit Ihrem neuen Passwort einloggen',
  },
  tender: {
    create: 'Erstellen Sie eine neue Ausschreibung',
    edit: 'Ausschreibung aktualisieren',
    tenderActions: 'Aktionen',
    mainData: 'Hauptinformationen',
    date: 'Datum',
    platformReferenceNumber: 'Plattform-Referenznummer',
    clientReferenceNumber: 'Kundenreferenznummer',
    tenderCategory: 'Ausschreibungskategorie',
    procedureType: 'Verfahrenstyp',
    company: 'Firma',
    tenderOwner: 'Angebotsinhaber',
    addressData: 'Adressdaten',
    mainCompanyAddress: 'Hauptfirmenadresse',
    address: 'Adresse',
    zipCode: 'Postleitzahl',
    city: 'Stadt',
    country: 'Land',
    tenderObjectLocation: 'Standort des Ausschreibungsobjekts',
    tenderOwnerData: 'Daten des Angebotsinhabers',
    additionalContactPersonData: 'Zusätzliche Kontaktdaten des Ansprechpartners',
    firstName: 'Vorname',
    lastName: 'Nachname',
    phone: 'Telefon',
    mobile: 'Mobil',
    email: 'Email',
    position: 'Position',
    title: 'Ausschreibungstitel',
    mainTitle: 'Geben Sie den Haupttitel ein',
    internalTitle: 'Geben Sie den internen Titel ein (nicht öffentlich)',
    tenderDescription: 'Ausschreibungstext',
    descriptionField: 'Geben Sie die Hauptangebotsbeschreibung ein',
    dataContainer: 'Datencontainer',
    containerSubtitle: 'Geben Sie den Untertitel ein',
    containerDescription: 'Geben Sie die Abschnittsbeschreibung ein',
    addRow: 'Zeile hinzufügen',
    remove: 'Löschen',
    removeSubtitle: 'Untertitel entfernen',
    removeDescription: 'Beschreibung entfernen',
    removeField: 'Feld entfernen',
    editField: 'Feld bearbeiten',
    addField: 'Feld hinzufügen',
    removeRow: 'Diese Zeile entfernen',
    addContainer: '+ Datencontainer hinzufügen',
    removeContainerNotice: 'Bist du sicher? Die Daten könnten verloren gehen',
    inputFieldType: 'Welche Eingabefelder möchten Sie hinzufügen?',
    fieldLabel: 'Feldbezeichnung',
    chooseInputType: 'Eingabefeldtyp auswählen',
    addressNotice: 'Welchen Adresstyp möchten Sie hinzufügen?',
    chooseAddressType: 'Adresstyp auswählen',
    chooseInputFieldTitle: 'Welche Eingabefelder möchten Sie hinzufügen?',
    moveDown: 'Absteigen',
    moveUp: 'Aufsteigen',
    preview: 'Ausschreibungsvorschau',
    removeAddress: 'Adresse entfernen',
    makeOffer: 'Angebot machen',
    acceptingOffersFrom: 'Annahme von Angeboten von',
    acceptingOffersTo: 'Annahme von Angeboten an',
    dateFormatHint: 'Format YYYY-MM-DD',
    platformCategory: 'Esora-Kategorie',
    exitPreview: 'Vorschau beenden',
    resetAndExit: 'Zurücksetzen und beenden',
    removeStep: 'Schritt entfernen',
    dataRequested: 'Diese Daten werden von der anderen Partei angefordert',
    enterStepperTitle: 'Stepper-Titel eingeben',
    stepperTitle: 'Stepper-Titel',
    details: 'Ausschreibungsdetails',
    dataSaved: 'Ausschreibungsdaten erfolgreich gespeichert',
    addressDataNotSelected: 'Sie müssen den Adresstyp und eine der angebotenen Adressen auswählen, um fortzufahren',
    tenderTypeStateAreaContract: 'Staatsvertragsbereich?',
    step1: 'Schritt 1',
    actionMenu: {
      addContainer: 'Neuen Datencontainer hinzufügen',
      duplicateTender: 'Doppelte Ausschreibung',
      deleteTender: 'Ausschreibung löschen',
    },
  },
  companies: {
    companyList: 'Firmenliste',
    edit: 'Unternehmensdaten aktualisieren',
    create: 'Neues Unternehmen hinzufügen',
    name: 'Name',
    address: 'Adresse',
    addressAdditional: 'Adresszeile 2',
    zipCode: 'Postleitzahl',
    city: 'Stadt',
    state: 'Zustand',
    country: 'Land',
    phone: 'Telefon',
    mobile: 'Mobil',
    email: 'Email',
    website: 'Website URL',
    correspondenceEmail: 'Korrespondenz-E-Mail',
    companyIdentificationNumber: 'Identifikationsnummer',
    companyIdentificationType: 'Identifikationstyp',
    dataSaved: 'Firmendaten gespeichert',
    companyAddresses: 'Firmenadressen',
    companyUsers: 'Firmenbenutzer',
    addOrEditCompanyAddress: 'Firmenadresse hinzufügen/bearbeiten',
    addressType: 'Adresstyp',
    streetNumber: 'Hausnummer',
    additionalAddressLine1: 'Zusätzliche Adresszeile 1',
    additionalAddressLine2: 'Zusätzliche Adresszeile 2',
    language: 'Sprache',
    description: 'Beschreibung',
  },
  users: {
    userList: 'Benutzerliste',
    create: 'Neuen Benutzer hinzufügen',
    addOrEditUser: 'Benutzer hinzufügen/bearbeiten',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    correspondenceEmail: 'Korrespondenz-E-Mail',
    language: 'Language',
    correspondenceLanguage: 'Korrespondenzsprache',
    mobile: 'Mobil',
    phone: 'Telefon',
    placementAddress: 'Platzierungsadresse',
    title: 'Titel',
    function: 'Funktion',
    twoFactorVerificationMethod: 'Zwei-Faktor-Authentifizierungsmethode',
    userRole: 'Benutzer-Rolle',
    userType: 'Benutzertyp',
    password: 'Passwort',
    passwordConfirmation: 'Passwort Bestätigung',
    dataSaved: 'Benutzerdaten erfolgreich gespeichert',
    validationError: 'There was an error saving the user. Please check the fields and try again',
  },
  languages: {
    german: 'Deutsch',
    french: 'Französisch',
    italian: 'Italienisch',
    english: 'Englisch',
  },
  footer: {
    copyright: 'Urheberrechte ©',
    impressum: 'Impressum',
  },

  // backend error keys
  invalid_credentials: 'Ungültige Anmeldeinformationen',
  company_account_not_enabled: 'Das Firmenkonto ist nicht aktiviert, bitte kontaktieren Sie den Support',
  user_account_not_enabled: 'Ihr Konto ist nicht aktiviert, bitte kontaktieren Sie den Support',
  email_taken: 'Entschuldigung, diese E-Mail-Adresse ist bereits registriert. Bitte einen anderen verwenden',
  password_not_matched: 'Passwort und Passwortbestätigung stimmen nicht überein, bitte korrigieren Sie die Felder',
  not_found: 'Angeforderte Ressource wurde auf dem Server nicht gefunden',
  weak_password: 'Das Feldpasswort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Ziffer mit mindestens 8 Zeichen enthalten',
  general_error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support',
  general_success: 'Aktion erfolgreich abgeschlossen',
}
